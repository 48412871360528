<template>
  <div class="for-organizations">
    <div class="intro-full-w bg-img">
      <intro-page :pageId="currentPageId"></intro-page>
    </div>
    <div >
    <two-sides-list v-if="customText && customText.sectors" label="TARGET SECTORS" :title="customText.sectors.organizations_sectors_titolo_intro" :description="customText.sectors.organizations_sectors_intro" :items="listItems"/>
    <div class="offset-tiles">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 order-lg-2 image ">
            <img src="../assets/home-careers.jpg" alt="">
          </div>
          <div class="col-lg-6 order-lg-1 d-flex flex-column justify-content-center text" v-if="customText && customText.facilities">
            <div>
              <h6>Facilities</h6>
              <h3 class=light>{{customText.facilities.organizations_facilities_titolo}}</h3>
              <div v-html="customText.facilities.organizations_facilities_intro"></div>
              <router-link class="read-more white" to="/experimental-facilities">read more</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section mt-2 mt-5 benefits" v-if="customText && customText.benefits">
      <div class="container">
        <h6>BENEFITS</h6>
        <h2 class="light">{{ customText.benefits.organizations_benefits_titolo_intro }}</h2>
        <div class="row">
          <div class="col-lg-6" v-if="customText.benefits.organizations_benefits_intro">
          </div>
        </div>
        <div class="row my-5">
          <div class="col-lg-5 benefit-item">
            <h3 class="bullet-list-deco green-deco">{{ customText.benefits.organizations_benefits_titolo_1 }}</h3>
            <div v-html="customText.benefits.organizations_benefits_testo_1"></div>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-5 benefit-item">
            <h3 class="bullet-list-deco green-deco">{{ customText.benefits.organizations_benefits_titolo_2 }}</h3>
            <div v-html="customText.benefits.organizations_benefits_testo_2"></div>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-lg-5 benefit-item">
            <h3 class="bullet-list-deco green-deco">{{ customText.benefits.organizations_benefits_titolo_3 }}</h3>
            <div v-html="customText.benefits.organizations_benefits_testo_3"></div>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-5 benefit-item">
            <h3 class="bullet-list-deco green-deco">{{ customText.benefits.organizations_benefits_titolo_4 }}</h3>
            <div v-html="customText.benefits.organizations_benefits_testo_4"></div>
          </div>
        </div>

      </div>
    </div>
    <div class="section partners" v-if="customText && customText.partners">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h6>Partners</h6>
            <h2 class="light">{{ customText.partners.organizations_partners_titolo_intro }}</h2>
            <div v-html="customText.partners.organizations_partners_intro"></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import IntroPage from "../components/IntroPage.vue";
import TwoSidesList from "../components/TwoSidesList.vue";

export default {
  name: "organizations",
  components: {
    IntroPage,
    TwoSidesList
  },
  data() {
    return {
      currentPageId: "765a230f-5082-4809-bcc0-237b355e7ecd",
      listDescription: "The following are some of the industries using HPC and the types of workloads HPC is helping them perform:",
      customText: undefined,
      listItems:[]
    };
  },
    methods: {
    fetchPageData(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/custom-texts/organizations`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.customText = json.data;
          
          
          //console.log('this.customText ', this.customText);

          if(this.customText && this.customText.sectors){
            for(let i = 0; i < 30; i++ ){
              if(this.customText.sectors['organizations_sectors_testo_' + i]){
                this.listItems.push(
                  {
                    title: this.customText.sectors['organizations_sectors_titolo_' + i],
                    text: this.customText.sectors['organizations_sectors_testo_' + i],
                  }
                )
              }
            }
          }
        })
        .catch(function (error) {
          console.log("Request failed", error);
        });
      },
  },
  mounted() {
    this.fetchPageData()
  }
};
</script>
<style lang="scss" scoped>
.intro-full-w {
  background: url(../assets/intro-for-students.png) no-repeat;
  background-position: center right;
  background-size: contain;
}
.offset-tiles{
  position: relative;
  &::before{
    background: transparent; 
  }
  @media (min-width:992px) {
    padding-top: 100px;
    &::before{
      top: 0;
      height: 100px;
      background-color: #2a3446;
    }
  }
  .row div.image{
    max-height: none;
      @media (min-width:992px) {
        top: -100px
      }
  }
}
.benefits{
  h3{
    text-transform: uppercase;
    font-size: 22px;
    font-family: Ubuntu;
    font-weight: 500;
  }
  
}
.benefit-item{
  &::after{
    content: '';
    background-color:#1DC07E;
    width: 65px;
    display: inline-block;
    height: 2px;
  }
}
.cta-big.icon-university:before {
  background: url("../assets/icon-university.svg");
}
</style>